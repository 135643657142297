.pull-down_la-label {
  position: absolute;
  top: 50%;
  left: 8%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  opacity: 1;
  z-index: 1;
  z-index: 1;
  background-color: #ffffff;
  padding: 6px;
  width: 77%;
  width: 80%;
  pointer-events: none;
  font-size: 12px;
}

.pull-down_la-label-no-show {
  position: absolute;
  top: 50%;
  left: 8%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  opacity: 0;
  z-index: 1;
  z-index: 1;
  background-color: #ffffff;
  padding: 6px;
  width: 77%;
  width: 80%;
  pointer-events: none;
  font-size: 12px;
}

.pull-down_la-select {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border-color: #aaa;
  border-radius: 30px;
  padding: 10px 32px 10px 24px;
  width: 250px;
  color: #000;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.pull-down_la-select-option-default {
  display: none;

  font-weight: normal;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
  font-family: YakuHanJP, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', '游ゴシック', YuGothic,
    'ヒラギノ角ゴ ProN W3', Hiragino Kaku Gothic ProN, Arial, 'メイリオ', Meiryo, sans-serif;
}

.pull-down_la-select-option {
  font-weight: normal;
  display: block;
  white-space: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
  font-family: YakuHanJP, -apple-system, BlinkMacSystemFont, 'Helvetica Neue', '游ゴシック', YuGothic,
    'ヒラギノ角ゴ ProN W3', Hiragino Kaku Gothic ProN, Arial, 'メイリオ', Meiryo, sans-serif;
}
