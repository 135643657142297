.none-jk-home-p-info-title {
  padding-top: 16%;
  margin: 0 0 6.3% 0;
  text-align: center;
}

.none-jk-home-p-info-title span {
  display: block;
  font-weight: 400;
}

.none-jk-home-p-info-span {
  margin-bottom: 12px;

  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 1.7px;
}

.none-jk-home-p-info-release-date {
  color: #5c5c5c;
  font-weight: 500;
  font-size: 13px;
  font-family: 'Inter';
  letter-spacing: 0.5px;
}

.p-info-home__title {
  margin: 0 0 6.3% 0;
  padding-top: 12%;
  text-align: center;
}

.p-info-home__title span {
  display: block;
  font-weight: 400;
}

.p-info-home__song {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 1.7px;
}

.p-info-home__release {
  color: #5c5c5c;
  font-weight: 500;
  font-size: 13px;
  font-family: 'Inter';
  letter-spacing: 0.5px;
}

.p-info-reserve-home__lead {
  margin-bottom: 11px;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.8px;
}

@media screen and (min-width: 768px) {
  .none-jk-home-p-info-title {
    margin: 0 0 5% 0;
    padding-top: 14%;
    text-align: center;
  }

  .none-jk-home-p-info-span {
    margin-bottom: 11px;
    font-size: 31px;
    letter-spacing: 3px;
  }

  .none-jk-home-p-info-release-date {
    font-size: 15px;
    letter-spacing: 1.4px;
  }

  .p-info-home__title {
    margin: 0 0 5% 0;
    padding-top: 12.4%;
    text-align: left;
  }

  .p-info-home__song {
    margin-bottom: 12px;
    font-size: 31px;
  }

  .p-info-home__release {
    font-size: 15px;
    letter-spacing: 1.4px;
  }

  .p-info-reserve-home__lead {
    margin-bottom: 19px;
    line-height: 1.8;
    letter-spacing: 1.7px;
  }
}
