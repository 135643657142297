.is-apple::before {
  position: absolute;
  top: 6%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0.4;
  -webkit-filter: blur(8px);
  filter: blur(8px);
  -webkit-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  border-radius: 40px;
  background: #ff2d55;
  width: 90%;
  height: 100%;
  content: '';
}

.is-apple::after {
  position: absolute;
  top: 50%;
  left: 9%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url(../../assert/icon/icon_apple_music.svg) no-repeat center center;
  background-size: contain;
  width: 18px;
  height: 18px;
  content: '';
}

.is-apple-disabled::before {
  opacity: 0;
  -webkit-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
}

.is-apple-disabled::after {
  position: absolute;
  top: 50%;
  left: 9%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url(../../assert/icon/icon_apple_music.svg) no-repeat center center;
  background-size: contain;
  width: 18px;
  height: 18px;
  content: '';
}

.is-inner-apple {
  background-color: #ff2d55;
  line-height: 1;
}

.is-inner-apple-span {
  display: inline-block;
  text-align: center;
}

.is-inner-apple-span-image {
  vertical-align: middle;
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .is-apple::before {
    top: 20%;
    width: 80%;
  }

  .is-apple::after {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 767px) {
}

@-webkit-keyframes check-anime {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  30% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes check-anime {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  30% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
