@charset "UTF-8";

.body {
  color: #000;
  font-weight: 400;
  font-family: 'Noto Sans JP';
}

.l-wrap {
  position: relative;
}

.p-hero {
  position: relative;
}

.p-hero__img {
  overflow: hidden;
}

.p-info {
  position: relative;
}

.p-info__inner {
  margin: 0 auto;
  width: 90%;
}

.p-info__title {
  margin: 0 0 6.3% 0;
  padding-top: 12%;
  text-align: center;
  line-height: 1;
}

.p-info__title span {
  display: block;
  font-weight: 400;
}

.p-info__song {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: 1.7px;
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.p-info__release {
  color: #5c5c5c;
  font-weight: 500;
  font-size: 13px;
  font-family: 'Inter';
  letter-spacing: 0.5px;
}

.p-info__lead {
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.5px;
}

.p-info__reserve-lead {
  margin-bottom: 11px;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: 0.8px;
}

.p-info__reserve-note {
  margin-bottom: 30px;
  color: #5c5c5c;
  font-size: 11px;
  line-height: 1.9;
  letter-spacing: 1px;
}

.p-redirect_count {
  font-size: large;
  color: #F00;
}