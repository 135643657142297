.is-spotify::before {
  position: absolute;
  top: 6%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0.4;
  -webkit-filter: blur(8px);
  filter: blur(8px);
  -webkit-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  border-radius: 40px;
  background-color: #1ed760;
  width: 90%;
  height: 100%;
  content: '';
}

.is-spotify::after {
  position: absolute;
  top: 50%;
  left: 9%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url(../../assert/icon/icon_spotify.svg) no-repeat center center;
  background-size: contain;
  width: 21px;
  height: 21px;
  content: '';
}

.is-inner-spotify {
  background-color: #1ed760;
  line-height: 1;
}

.is-inner-spotify-span {
  display: inline-block;
  text-align: center;
}

.is-inner-spotify-span-image {
  vertical-align: middle;
  max-width: 100%;
}

.is-spotify-disabled::before {
  opacity: 0;
  -webkit-transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
  transition: all 1s cubic-bezier(0.22, 1, 0.36, 1);
}

.is-spotify-disabled::after {
  position: absolute;
  top: 50%;
  left: 9%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: url(../../assert/icon/icon_spotify.svg) no-repeat center center;
  background-size: contain;
  width: 18px;
  height: 18px;
  content: '';
}

@media screen and (min-width: 768px) {
  .is-spotify::before {
    top: 20%;
    width: 80%;
  }

  .is-spotify::after {
    width: 24px;
    height: 24px;
  }
}
